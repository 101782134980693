<template>
  <div class="store-history">
    <div class="d-flex justify-space-between pa-5">
      <v-icon color="red" @click="$router.push({name: 'Store', params: {lang: $store.state.locale}})">mdi-chevron-left</v-icon>
      <v-spacer></v-spacer>
      <div class="primary--text">{{ $t('storeHistory') }}</div>
      <v-spacer></v-spacer>
    </div>
    <div class="info-card pa-8 primary mx-auto d-flex">
      <img class="mr-5" src="https://fakeimg.pl/80" alt="level">
      <div class="white--text">
        <div>{{ $t('level') }}: 青銅</div>
        <div>{{ $t('user') }}: 0{{ $store.state.userInfo.phone }}</div>
        <div><span class="rem-20">{{ $store.state.userInfo.balance }}</span> {{ $t('consumpAmount') }}</div>
      </div>
    </div>
    <div class="history-list grey lighten-3 pa-5 pb-10">
      <div class="d-flex justify-space-between mb-5">
        <div class="d-flex align-center">
          <v-icon>mdi-currency-usd</v-icon>
          <div>{{ $t('myStoreHistory') }}</div>
        </div>
        <div class="d-flex align-center">
          <div>{{ $t('howToStore') }}</div>
          <v-icon>mdi-help-circle-outline</v-icon>
        </div>
      </div>
      <div class="history-item d-flex align-center rounded white px-5 py-3 mx-3 mb-5" v-for="(item, i) in history" :key="i">
        <img class="mr-5" src="https://fakeimg.pl/80" alt="level" width="40px">
        <div>
          <div><span class="rem-13">{{ item.amount }}</span> <span class="text-caption">TBT</span></div>
          <div class="text-caption">{{ item.time }}</div>
        </div>
      </div>
      <div class="text-center text-caption grey--text mb-5">{{ $t('noMoreStoreHistory') }}</div>
      <btn :buttonText="'storeNow'" isBlock></btn>
    </div>
  </div>
</template>
<script>
import btn from '@/components/btn'
export default {
  name: "Store-History",
  data() {
    return {
      history: [
        {
          amount: 60000,
          time: '2021.12.10 14:57'
        },
        {
          amount: 60000,
          time: '2021.12.10 14:57'
        },
        {
          amount: 60000,
          time: '2021.12.10 14:57'
        },
      ]
    }
  },
  components:{
    btn
  }
}
</script>
<style lang="scss">
.store-history{
  .info-card{
    width: 90%;
    padding-bottom: 60px !important;
  }
  .history-list{
    box-shadow: 0 0 20px rgba(100, 100, 100, 0.4);
    position: relative;
    .history-item{
      box-shadow: 0 0 10px rgba(100, 100, 100, 0.3);
    }
  }
  .history-list::before, .history-list::after{
    content: "";
    position: absolute; /*絕對定位*/
    width: 50%;
    height: 60px;
    top: -60px;
    left: 0;
    display: inline-block;
    border-right: 50vw solid transparent;
    border-bottom: 60px solid #EEEEEE;
    filter: drop-shadow(0 -10px 7px rgba(0, 0, 0, .1));
  }
  .history-list::after{
    right: 0;
    left: auto !important;
    border-right: none !important;
    border-left: 50vw solid transparent;
  }
}
</style>